export const environment = {
  production: false,  
  APIURL: "https://api.giaongay.com.vn/api/v1/",    
  APIRootURL: "https://api.giaongay.com.vn/",    
  LoginURL: "https://login.giaongay.com.vn/",
  RegisterURL: "https://register.giaongay.com.vn/",
  ShopURL: "https://shop.giaongay.com.vn/#/Homepage",  
  ShipperURL: "https://shipper.giaongay.com.vn/#/Homepage",  
  DomainURL: "https://login.giaongay.com.vn/",
  DomainFullURL: "https://login.giaongay.com.vn/#/",
  DieuHanhID: 2, 
  ShopID: 3, 
  ShipperID: 4, 
  InitializationString: "",  
  InitializationNumber: 0, 
  AuthenticationToken: "AuthenticationToken",
  MembershipID: "MembershipID",  
  LoginSuccess: "Đăng nhập thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",   
  RegisterSuccess: "Đăng ký thành công.",
  RegisterNotSuccess: "Đăng ký không thành công.",  
  EmailRequired: "Email is required.", 
  EmailExists: "Email đã được đăng ký.", 
  PhoneExists: "Điện thoại đã được đăng ký.", 
  EmailNotExists: "Email chưa đăng ký.", 
  MOMOExists: "MOMO đã được đăng ký.",   
  ForgotPassword: "Mật khẩu mới đã được gửi đến email của bạn.",
};

